import Geocode from "react-geocode";

export function getCountryFromLatLng(lat, lng) {
  return new Promise((resolve, reject) => {
    Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            // eslint-disable-next-line default-case
            switch (response.results[0].address_components[i].types[j]) {
              case "country":
                const country =
                  response.results[0].address_components[i].long_name;
                resolve(country);
                break;
            }
          }
        }
      },
      (error) => {
        reject(error.message);
      }
    );
  });
}

export function getCountryCodeFromLatLng(lat, lng) {
  return new Promise((resolve, reject) => {
    Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            // eslint-disable-next-line default-case
            switch (response.results[0].address_components[i].types[j]) {
              case "country":
                const country =
                  response.results[0].address_components[i].short_name;
                resolve(country);
                break;
            }
          }
        }
      },
      (error) => {
        reject(error.message);
      }
    );
  });
}
