import React, { useCallback, useEffect, useMemo } from "react";

import Button from "../../common/Button";
import {
  addBankAccount,
  cashWithdraw,
  getConnectedAccount,
  getPreviousTransactions,
  redirectToConnectAccountLinks,
} from "../../../store/thunks";
import {
  areCapabilitiesVerified,
  countries,
  formatMessageTime,
} from "../../../utils/helperFunctions";
import InputField from "../../common/InputField";
import Error from "../../common/Error";
import { useFormik } from "formik";
import Select from "react-select";
import { BsBank, BsCreditCard } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { setBankModal } from "../../../store/slices/paymentSlice";
import { BankAccountSchema } from "../../../utils/PostJobSchema";
import { getEarnings } from "../../../store/thunks/earningsThunk";
import { getCountryCodeFromLatLng } from "../../../services/geocode.services";
import { openErrorMessage } from "../../../store/slices/toastSlice";
import { useAuthContext } from "../../../features/authentication";

const initialValues = {
  accountHolderName: "",
  bankAccountNumber: "",
  routingNumber: "",
  country: countries.find((country) => country.code === "US") ?? {},
  currency: "",
};

const BankTransfer = ({ toast }) => {
  const { updateProfile } = useAuthContext();
  const {
    connectAccount,
    bankModal,
    loading,
    bankLoading,
    withdrawLoading,
    previousTransfers,
    redirectLoading,
  } = useSelector((state) => state.payment);
  const { earnings, loading: earningLoading } = useSelector(
    (state) => state.earnings
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConnectedAccount());
    dispatch(getPreviousTransactions());
    dispatch(getEarnings());
  }, [dispatch]);

  const isConnectVerified = useMemo(() => {
    return (
      connectAccount && areCapabilitiesVerified(connectAccount.capabilities)
    );
  }, [connectAccount]);

  const toggleBankModal = () => {
    dispatch(setBankModal(!bankModal));
  };
  const withdrawSuccessCallback = useCallback(() => {
    updateProfile({ currentBalance: 0 });
  }, [updateProfile]);
  const makeTransferConfirm = useCallback(() => {
    if (!connectAccount) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const countryCode = await getCountryCodeFromLatLng(
            latitude,
            longitude
          );
          dispatch(cashWithdraw({ countryCode }));
        } catch (err) {
          console.error(err?.message);
          openErrorMessage("Something went wrong, please try again later");
        }
      });
    } else {
      dispatch(cashWithdraw({ withdrawSuccessCallback }));
    }
  }, [connectAccount, dispatch, withdrawSuccessCallback]);
  const accounts = connectAccount?.external_accounts?.data;
  const currentBalance = earnings?.user?.currentBalance || 0;
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: BankAccountSchema,
    onSubmit: (values, action) => {
      values.currency = values.country.currency.code;
      dispatch(addBankAccount(values));
    },
  });
  return (
    <>
      <div className="bank-details-section d-flex flex-column flex-sm-row">
        <div className="bank-details">
          <h5>Bank Transfer</h5>
          <hr />
          <div>
            {accounts?.length > 0 ? (
              accounts.map((account, index) => {
                return (
                  <div className="d-flex align-items-start gap-2" key={index}>
                    <BsBank className="bank-icon falcon-clr" />
                    <div>
                      <p className="bank-name">{account.bank_name}</p>
                      <p className="username text-capitalize ms-1">
                        {account.account_holder_name}
                      </p>
                      <p className="bank-name">
                        ****_****_****_{account.last4}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : loading ? (
              <p className="text-center grey-clr">Loading...</p>
            ) : (
              <p>No Bank account added</p>
            )}

            <div className="d-flex" style={{ gap: 8 }}>
              <Button
                className="accept-btn withdraw mt-4"
                children={
                  withdrawLoading || earningLoading ? (
                    <div
                      className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  ) : !withdrawLoading && !isConnectVerified ? (
                    "Verify your Account"
                  ) : (
                    "Withdraw"
                  )
                }
                disabled={withdrawLoading || earningLoading}
                onClick={() => {
                  makeTransferConfirm();
                }}
              />
              {accounts?.length > 0 && (
                <Button
                  className="accept-btn withdraw mt-4"
                  children={
                    withdrawLoading || earningLoading || redirectLoading ? (
                      <div
                        className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    ) : (
                      <div>Edit Bank Account</div>
                    )
                  }
                  disabled={
                    withdrawLoading || earningLoading || redirectLoading
                  }
                  onClick={() => dispatch(redirectToConnectAccountLinks())}
                />
              )}
            </div>
          </div>
        </div>
        <div className="total-payment">
          <p className="username grey-clr ">Total Amount</p>
          {earningLoading ? (
            <div className="spinner-border spinner-border-sm and spinner-grow-sm mt-2">
              <span className="sr-only"></span>
            </div>
          ) : (
            <>
              <p className="dollar">$</p>
              <h4 className="total-income">{currentBalance}</h4>
            </>
          )}
        </div>
      </div>
      <div className="mt-4 previous-section">
        <h5>Previous Transaction</h5>
        <hr />
        {loading ? (
          <p className="text-center grey-clr">Loading...</p>
        ) : previousTransfers === 0 ? (
          <p className="text-center grey-clr">No Previous Transfers</p>
        ) : (
          previousTransfers?.map((item, index) => {
            const initDate = new Date(item.created * 1000);
            const arrivalDate = new Date(item.arrival_date * 1000);
            const transferBalance = parseInt(
              item.metadata?.transferBalance ?? item?.amount ?? 0
            );
            return (
              <div
                className="d-flex flex-column flex-sm-row align-items-start justify-content-between gap-2 transaction"
                key={index}
              >
                <div>
                  <p className="dark">Total Amount</p>
                  <p className="light grey-clr">${transferBalance / 100}</p>
                </div>
                <div>
                  <p className="dark">
                    Initiate Date:{" "}
                    <span className="light grey-clr">
                      {initDate.toLocaleDateString()}
                    </span>
                  </p>
                  <p className="dark">
                    Arrival Date:{" "}
                    <span className="light grey-clr">
                      {arrivalDate.toLocaleDateString()}
                    </span>
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>

      <Modal isOpen={bankModal} toggle={toggleBankModal} size="md">
        <ModalHeader toggle={toggleBankModal}></ModalHeader>
        <ModalBody>
          {accounts?.length === 0 && (
            <div className="add-card-form px-3 px-sm-5">
              <form className="form">
                <h5 className="text-center heading">Add Bank Account</h5>
                <div className="mb-3">
                  <label className="d-block regular" htmlFor="">
                    Routing Number
                  </label>
                  <InputField
                    className="p-2"
                    name="routingNumber"
                    id="routingNumber"
                    value={values.routingNumber}
                    onChange={handleChange}
                    autoComplete="off"
                    onBlur={handleBlur}
                  />
                  {errors.routingNumber && touched.routingNumber && (
                    <Error text={errors.routingNumber} />
                  )}
                </div>

                <div className="mb-3">
                  <label className="d-block regular" htmlFor="">
                    Account Holder Name
                  </label>
                  <InputField
                    className="p-2"
                    name="accountHolderName"
                    id="accountHolderName"
                    value={values.accountHolderName}
                    onChange={handleChange}
                    autoComplete="off"
                    onBlur={handleBlur}
                  />
                  {errors.accountHolderName && touched.accountHolderName && (
                    <Error text={errors.accountHolderName} />
                  )}
                </div>
                <div className="mb-3">
                  <label className="d-block regular" htmlFor="">
                    Bank Account Number
                  </label>
                  <InputField
                    className="p-2"
                    name="bankAccountNumber"
                    id="bankAccountNumber"
                    value={values.bankAccountNumber}
                    onChange={handleChange}
                    autoComplete="off"
                    onBlur={handleBlur}
                  />
                  {errors.bankAccountNumber && touched.bankAccountNumber && (
                    <Error text={errors.bankAccountNumber} />
                  )}
                </div>
                <div className="mb-3">
                  <label className="d-block regular" htmlFor="">
                    Select Country
                  </label>
                  <Select
                    className="select"
                    id="country"
                    name="country"
                    placeholder="Select Country..."
                    options={countries}
                    getOptionLabel={(option) => {
                      return (
                        <div className="text-capitalize">{option.name}</div>
                      );
                    }}
                    getOptionValue={(option) => option.name}
                    onChange={(selectedOption) =>
                      setFieldValue("country", selectedOption)
                    }
                    value={values.country}
                  />
                  {errors.country && touched.country && (
                    <Error text={errors.country} />
                  )}
                </div>
                <div className="d-flex justify-content-center mt-4"></div>
              </form>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="go-offline px-3 py-1" onClick={toggleBankModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="add-bank-btn"
            children={
              bankLoading ? (
                <div
                  className="spinner-border spinner-border-sm and spinner-grow-sm mb-0"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              ) : (
                "Add Bank Account"
              )
            }
            onClick={handleSubmit}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BankTransfer;
