import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../../api/axios";
import { setBankModal } from "../slices/paymentSlice";
import { getEarnings } from "./earningsThunk";
import { openErrorMessage, openSuccessMessage } from "../slices/toastSlice";

export const getCardsList = createAsyncThunk(
  "payment/getCardsList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/payments/cards-list");
      return response.data.paymentMethods;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postCardDetails = createAsyncThunk(
  "payment/postCardDetails",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { cardNumber, cvc, expiryMonth, expiryYear } = data;

      const card = {
        cardNumber: cardNumber,
        cvc: cvc,
        expiryMonth: expiryMonth,
        expiryYear: expiryYear,
      };

      const response = await axios.post("/payments/payment-method", card);

      dispatch(openSuccessMessage(response.data.message));
      return response.data.paymentMethod;
    } catch (error) {
      dispatch(openErrorMessage(error.message));
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const getConnectedAccount = createAsyncThunk(
  "payment/getConnectedAccount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/payments/connect-account");
      return response.data.connectAccount;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const redirectToConnectAccountLinks = createAsyncThunk(
  "payment/redirectToConnectAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const verifyResponse = await axios.get("/payments/account-links");
      window.location.href = verifyResponse.data.accountLink.url;
      return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addBankAccount = createAsyncThunk(
  "payment/addBankAccount",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const {
        country,
        currency,
        accountHolderName,
        bankAccountNumber,
        routingNumber,
      } = payload;
      const countryCode = country.code;

      const response = await axios.post("/payments/bank-account", {
        currency,
        accountHolderName,
        bankAccountNumber,
        routingNumber,
        country: countryCode,
      });

      dispatch(setBankModal(false));
      dispatch(openSuccessMessage("Bank Account added"));

      return response.data;
    } catch (error) {
      dispatch(
        openErrorMessage(error?.response?.data?.error || "Something went wrong")
      );
      return rejectWithValue(error);
    }
  }
);

export const cashWithdraw = createAsyncThunk(
  "payment/cashWithdraw",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { withdrawSuccessCallback } = payload;
      const connectResponse = await axios.get(
        `/payments/connect-account?countryCode=${payload.countryCode}`
      );
      const connect = connectResponse.data.connectAccount;
      if (
        connect?.requirements?.currently_due?.length > 0 ||
        connect?.requirements?.errors?.length > 0 ||
        connect?.requirements?.pending_verification?.length > 0
      ) {
        const verifyResponse = await axios.get("/payments/account-links");
        window.location.href = verifyResponse.data.accountLink.url;
        return;
      }
      const response = await axios.get("/payments/confirm-transfer");
      dispatch(openSuccessMessage("Cash withdrawn Successfully"));
      dispatch(getPreviousTransactions());
      dispatch(getEarnings());
      if (withdrawSuccessCallback) {
        withdrawSuccessCallback();
      }
      return response.data;
    } catch (error) {
      const message =
        error?.response?.data?.message ??
        error?.response?.data?.error ??
        "Something went wrong. Please contact support";
      dispatch(openErrorMessage(message));
      return rejectWithValue(message);
    }
  }
);

export const getPreviousTransactions = createAsyncThunk(
  "payment/getPreviousTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/payments/get-transactions");

      return response.data.transactions.data;
    } catch (error) {}
  }
);

export const deletePaymentCard = createAsyncThunk(
  "payment/deletePaymentCard",
  async (payload, { rejectWithValue }) => {
    try {
      // data should contain the card id to be deleted from stripe account here we are just deleting it
      const response = await axios.delete(`/payment-method?pmId=${payload}`);
      return {
        message: response.data.message,
        id: payload,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const verifyBankAccount = createAsyncThunk(
//   "payment/verifyBankAccount",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axios.get("/payments/account-links");
//
//       return response?.data || {};
//     } catch (error) {
//
//       return rejectWithValue(error);
//     }
//   }
// );

// export const confirmTransfer = createAsyncThunk(
//   "payment/confirmTransfer",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axios.get("/payments/confirm-transfer");
//
//       return response.data;
//     } catch (error) {
//
//       return rejectWithValue(error);
//     }
//   }
// );
