import { createSlice } from "@reduxjs/toolkit";
import {
  addBankAccount,
  cashWithdraw,
  deletePaymentCard,
  getCardsList,
  getConnectedAccount,
  getPreviousTransactions,
  postCardDetails,
  redirectToConnectAccountLinks,
} from "../thunks/paymentThunk";

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.bankLoading = false;
  state.withdrawLoading = false;
  state.error = action.payload;
};
const handlegetCardsListPending = (state) => {
  state.loading = true;
  state.attachCards = null;
};
const handleCardsListFulfilled = (state, action) => {
  state.loading = false;
  state.attachCards = action.payload;
};
const handlePostCardDetailsFulfilled = (state, action) => {
  state.loading = false;
  state.attachCards = [action.payload, ...state.attachCards];
  state.paymentSection = 1;
};
const handleGetConnectAccountPending = (state, action) => {
  state.loading = true;
  state.connectAccount = null;
};
const handleGetConnectAccountFulfilled = (state, action) => {
  state.connectAccount = action.payload;
  state.loading = false;
};
const handleWithdrawPending = (state, action) => {
  state.withdrawLoading = true;
  state.error = null;
};
const handleWithdrawFulfilled = (state, action) => {
  state.loading = false;
  state.withdrawLoading = false;
  state.success = action.payload;
};
const handleAddBankPending = (state) => {
  state.bankLoading = true;
  state.error = null;
};
const handleAddBankFulfilled = (state, action) => {
  state.bankLoading = false;
  state.success = action.payload;
};
const handleGetPreviousFulfilled = (state, action) => {
  state.loading = false;
  state.previousTransfers = action.payload;
};

const handleDeletePaymentCardFulfilled = (state, action) => {
  state.attachCards = state.attachCards.filter(
    (card) => card !== action.payload.id
  );
  state.loading = false;
};

const connectAccountRedirectInit = (state, action) => {
  state.redirectLoading = true;
  state.error = null;
};

const initialState = {
  attachCards: null,
  loading: false,
  bankLoading: false,
  withdrawLoading: false,
  error: undefined,
  success: undefined,
  bankAccount: null,
  connectAccount: null,
  previousTransfers: [],
  paymentSection: 1,
  bankModal: false,
  redirectLoading: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentSection: (state, action) => {
      state.paymentSection = action.payload;
    },
    setBankModal: (state, action) => {
      state.bankModal = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCardsList.pending, handlePending)
      .addCase(getCardsList.fulfilled, handleCardsListFulfilled)
      .addCase(getCardsList.rejected, handleRejected)
      .addCase(postCardDetails.pending, handlePending)
      .addCase(postCardDetails.fulfilled, handlePostCardDetailsFulfilled)
      .addCase(postCardDetails.rejected, handleRejected)
      .addCase(getConnectedAccount.pending, handleGetConnectAccountPending)
      .addCase(getConnectedAccount.fulfilled, handleGetConnectAccountFulfilled)
      .addCase(getConnectedAccount.rejected, handleRejected)
      .addCase(getPreviousTransactions.pending, handlePending)
      .addCase(getPreviousTransactions.fulfilled, handleGetPreviousFulfilled)
      .addCase(getPreviousTransactions.rejected, handleRejected)
      .addCase(addBankAccount.pending, handleAddBankPending)
      .addCase(addBankAccount.fulfilled, handleAddBankFulfilled)
      .addCase(addBankAccount.rejected, handleRejected)
      .addCase(cashWithdraw.pending, handleWithdrawPending)
      .addCase(cashWithdraw.fulfilled, handleWithdrawFulfilled)
      .addCase(cashWithdraw.rejected, handleRejected)
      .addCase(deletePaymentCard.pending, handlePending)
      .addCase(deletePaymentCard.fulfilled, handleDeletePaymentCardFulfilled)
      .addCase(deletePaymentCard.rejected, handleRejected)
      .addCase(
        redirectToConnectAccountLinks.pending,
        connectAccountRedirectInit
      ),
});

export const { setPaymentSection, setBankModal } = paymentSlice.actions;
export default paymentSlice.reducer;
